import Swiper from "swiper";

const defaultSliderOptions = {
  slidesPerView: 1,
  spaceBetween: 10,
  speed: 500,
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    1170: {
      slidesPerView: 4,
      spaceBetween: 15,
    },
    992: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
    390: {
      slidesPerView: 2,
    },
  },
};

const defaultSliders = document.querySelectorAll(".defaultSlider-js");
if (defaultSliders) {
  defaultSliders.forEach((slider) => {
    if (slider.classList.contains("is-1")) {
      new Swiper(slider.querySelector(".swiper-container"), {
        slidesPerView: 1,
        speed: 500,
        autoplay: {
          delay: 5000,
        },
        navigation: {
          nextEl: slider.querySelector(".swiper-button-next"),
          prevEl: slider.querySelector(".swiper-button-prev"),
        },
      });
    } else {
      new Swiper(slider.querySelector(".swiper-container"), {
        ...defaultSliderOptions,
        navigation: {
          nextEl: slider.querySelector(".swiper-button-next"),
          prevEl: slider.querySelector(".swiper-button-prev"),
        },
      });
    }
  });
}
