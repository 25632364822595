import "intersection-observer";
import Headroom from "headroom.js";
import sal from "sal.js";

import "./sliders.js";

// loadClass
document.getElementById("theApp").classList.add("is-loaded");

// Sticky Header
const theHeader = document.getElementById("theHeader");
var headroom = new Headroom(theHeader, {
  offset: 100,
  classes: {
    initial: "is-sticky",
    // when scrolling up
    pinned: "is-pinned",
    // when scrolling down
    unpinned: "is-unpinned",
    // when above offset
    top: "is-top",
    // when below offset
    notTop: "is-not-top",
    // when at bottom of scoll area
    bottom: "is-bottom",
    // when not at bottom of scroll area
    notBottom: "is-not-bottom",
  },
});
headroom.init();

// Sal.js
sal({
  threshold: 0.1,
  once: true,
});

// LazyLoad Images
window.addEventListener("load", (event) => {
  // Observer
  const inViewElms = document.querySelectorAll(".inViewJs");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(
      (entry) => {
        const lazyImage = entry.target.querySelector("img[data-src]");
        if (entry.intersectionRatio > 0) {
          // console.log(lazyImage.querySelector("img[data-src]"));
          lazyImage.src = lazyImage.dataset.src;

          lazyImage.onload = function () {
            entry.target.classList.add("inViewJs-active");
            observer.unobserve(entry.target);
          };
        } else {
          // console.log("out of view");
          // entry.target.classList.remove("inViewJs-active");
        }
      },
      { rootMargin: "0px 0px 100px 0px" }
    );
  });

  inViewElms.forEach((elm) => {
    observer.observe(elm);
  });
});

// Search
const headerSearch = document.querySelector(".theHeader-search");
const headerSearchToggle = document.querySelector(".theHeader-search-toggle");

if (headerSearch && headerSearchToggle) {
  headerSearchToggle.addEventListener("click", (event) => {
    headerSearch.classList.toggle("is-active");
  });
}

// SidebarNav
const sidebarNav = document.querySelector(".sidebarNav");

if (sidebarNav) {
  const sidebarNav_items = sidebarNav.querySelectorAll(":scope > li > a");
  if (sidebarNav_items) {
    sidebarNav_items.forEach((el) => {
      el.addEventListener("click", (event) => {
        el.parentElement.classList.toggle("is-active");
      });
    });
  }
}

// tabs
function tabify(element) {
  const header = element.querySelector(".main-tabs-header");
  const content = element.querySelector(".main-tabs");
  const tab_headers = [...header.children];
  const tab_contents = [...content.children];
  let default_tab_index;
  tab_contents.forEach((x) => (x.style.display = "none"));
  let current_tab_index = -1;

  function setTab(index) {
    if (current_tab_index > -1) {
      tab_headers[current_tab_index].classList.remove("is-active");
      tab_contents[current_tab_index].style.display = "none";
    }
    tab_headers[index].classList.add("is-active");
    tab_contents[index].style.display = "block";
    current_tab_index = index;
  }

  default_tab_index = tab_headers.findIndex((x) => {
    return [...x.classList].indexOf("default-main-tab") > -1;
  });

  default_tab_index = default_tab_index === -1 ? 0 : default_tab_index;
  setTab(default_tab_index);
  tab_headers.forEach((x, i) => (x.onclick = (event) => setTab(i)));
}

// this is where the magic happens!
[...document.querySelectorAll(".main-tabs-container")].forEach((x) =>
  tabify(x)
);
